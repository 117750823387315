// 封装过滤器
// 过滤器的声明可以有下列多种写法
import i18n from '../plugins/i18n';
/**
 * Number formatting by unit
 * like 10000 => 10k
 * @param {number} num
 * @param {number} digits
 * @return {string}
 */
function numberFormatter(num, digits) {
  const si = [
    { value: 1e18, symbol: "E" },
    { value: 1e15, symbol: "P" },
    { value: 1e12, symbol: "T" },
    { value: 1e9, symbol: "G" },
    { value: 1e6, symbol: "M" },
    { value: 1e3, symbol: "k" }
  ];
  for (let i = 0; i < si.length; i++) {
    if (num >= si[i].value) {
      return (num / si[i].value + 0.1).toFixed(digits).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, '$1') + si[i].symbol;
    }
  }
  return num.toString();
}

/**
 * Number formatting by thousand
 * like 10000 => "10,000"
 * @param {number} num
 * @return {string}
 */
const toThousandFilter = (num) => {
  return (+num || 0).toString().replace(/^-?\d+/g, m => m.replace(/(?=(?!\b)(\d{3})+$)/g, ','));
};

const toThousandFilterTwo = (num) => {
  if (!num) return "0.00";
  num = Number(num).toFixed(2);
  const reNum = (+num || 0).toString().replace(/^-?\d+/g, m => m.replace(/(?=(?!\b)(\d{3})+$)/g, ','));
  if (reNum.indexOf(".") > -1) return reNum;
  return reNum + ".00";
};

const financingStatusId = (statusId) => {
  switch (statusId) {
    case "0":
      return 'Unregistered';
    case "1":
      return 'Supplier';
    case "2":
      return 'Financier';
    case "3":
      return 'BrandOwner';
    case "4":
      return 'Administrator';
    case "5":
      return 'Finance Manager';
  }
};
const loginType = (statusId) => {
  switch (statusId) {
    case "0":
      return 'UNREGISTERED';
    case "1":
      return 'LOAN';
    case "2":
      return 'INVEST';
    case "3":
      return 'CORE';
    case "4":
      return 'ADMIN';
    case "5":
      return 'MANAGER';
    case "6":
      return 'MANAGER';
  }
};

const financingStatus = (status) => {
  let t = '';
  if (status.statusId === "1" && !status.fundsSent) {
    t = i18n.t('financAppointment');
  } else if (status.statusId === "1" && status.fundsSent && !status.repaid) {
    t = i18n.t('financInRevenue');
  } else if (status.statusId === "1" && status.fundsSent && status.repaid) {
    t = i18n.t('financFinished');
  }
  return t;
};
const financingStat = (status) => {
  status = status.toString();
  let t = '';
  if (status === "1") {
    t = i18n.t('financApproval');
  } else if (status === "2") {
    t = i18n.t('financFundsSent');
  } else if (status === "3") {
    t = i18n.t('financFunded');
  } else if (status === "4") {
    t = i18n.t('financRepaid');
  } else if (status === "5") {
    t = i18n.t('financReject');
  }
  return t;
};
// 融资管理-当前阶段
const financingStageStatus = (status) => {
  let name = "";
  switch (status) {
    case "ALL": name = i18n.t('all'); break;
    case "1": name = i18n.t('financApproval'); break;
    case "2": name = i18n.t('financFundsSent'); break;
    case "3": name = i18n.t('financFunded'); break;
    case "4": name = i18n.t('financRepaid'); break;
    case "5": name = i18n.t('financReject'); break;
    default: name = ""; break;
  }
  return name;
};
const repayIdStatus = (status) => {
  let statusName = '';
  switch (status) {
    case 1:
      statusName = i18n.t('equalPrincipal');
      break;
  }
  return statusName;
};
const orderStatus = (status) => {
  let statusName = '';
  switch (status) {
    case 'NO':
      statusName = i18n.t('notSigned');
      break;
    case 'YES':
      statusName = i18n.t('signed');
      break;
  }
  return statusName;
};
const productStatus = (status) => {
  let statusName = '';
  switch (status) {
    case 'NO':
      statusName = i18n.t('distribution');
      break;
    case 'YES':
      statusName = i18n.t('production');
      break;
    case 'SEND':
      statusName = i18n.t('distributionGoods');
      break;
    case 'PAY':
      statusName = i18n.t('deliver');
      break;
  }
  return statusName;
};
// 认证状态
const authStatus = (status) => {
  status = status.toString();
  let statusName = '';
  switch (status) {
    case '0':
      statusName = i18n.t('responseStatus.unknown');
      break;
    case '1':
      statusName = i18n.t('responseStatus.processing');
      break;
    case '2':
      statusName = i18n.t('responseStatus.confirm');
      break;
    case '3':
      statusName = i18n.t('responseStatus.reject');
      break;
    case '9':
      statusName = i18n.t('responseStatus.unknown');
      break;
    default: statusName = i18n.t('responseStatus.unknown'); break;
  }
  return statusName;
};
// 我的持仓-投资状态
const investStatus = (status) => {
  let statusName = '';
  switch (status) {
    case 'APPOINTMENT':
      statusName = i18n.t('booking');
      break;
    case 'CANCEL':
      statusName = i18n.t('revoke');
      break;
    case 'REPAY':
      statusName = i18n.t('repaymentInProgress');
      break;
    case 'COMPLETE':
      statusName = i18n.t('settled');
      break;
    case 'OVERDUE':
      statusName = i18n.t('overdue');
      break;
  }
  return statusName;
};
// 时间戳转化为日期
const timeStampToDate = (time) => {
  let date = new Date(time);
  let Y = date.getFullYear() + '-';
  let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
  let D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate() + '';
  return Y + M + D;
};
const interestRate = string => {
  return `${(Number(string) * 100).toFixed(2)}%`;
};

/**
 * Upper case first char
 * like "wenyuan" => "Wenyuan"
 * @param {string} string
 * @return {string}
 */
const uppercaseFirst = string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

/**
  * 参数说明：
  * number：要格式化的数字
  * decimals：保留几位小数
  * thousands_sep：千分位符号
  * dec_point：小数点符号
  */
const numberFormat = (value, decimals, thousands_sep, dec_point) => {
  if (!value) return 0;
  if (!decimals && !isFinite(decimals)) decimals = 0;
  if (!thousands_sep) thousands_sep = ",";
  if (!dec_point) dec_point = ".";
  value = (value + '').replace(/[^0-9+-Ee.]/g, '');
  let n = !isFinite(+value) ? 0 : +value,
    prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
    sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
    dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
    s = '',
    toFixedFix = (n, prec) => {
      let k = Math.pow(10, prec);
      return '' + Math.ceil(n * k) / k;
    };
  s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
  let re = /(-?\d+)(\d{3})/;
  while (re.test(s[0])) {
    s[0] = s[0].replace(re, "$1" + sep + "$2");
  }
  if ((s[1] || '').length < prec) {
    s[1] = s[1] || '';
    s[1] += new Array(prec - s[1].length + 1).join('0');
  }
  return s.join(dec);
};
const reNumber = (value) => {
  value = value.toString().replaceAll(",", "");
  return parseFloat(value.replace(/[^\d\.-]/g, ""));
};

const categoryFormat = (value) => {
  // RECHARGE:充值;WITHDRAW:提现
  let name = "";
  switch (value) {
    case "RECHARGE": name = i18n.t('statusI18n.Deposit'); break;
    case "WITHDRAW": name = i18n.t('statusI18n.Withdrawal'); break;
    default: name = ""; break;
  }
  return name;
};
const processFormat = (value) => {
  // FIRST:初审;SECOND:复审;CONFIRM:通过;REJECT:驳回;
  let name = "";
  switch (value) {
    case "FIRST": name = i18n.t('statusI18n.Verifying'); break;
    case "SECOND": name = i18n.t('statusI18n.Reviewing'); break;
    case "CONFIRM": name = i18n.t('statusI18n.Approved'); break;
    case "REJECT": name = i18n.t('statusI18n.Rejected'); break;
    default: name = ""; break;
  }
  return name;
};
// 应收账款 状态
const filterAssetsStatus = (value) => {
  let name = "";
  switch (value) {
    case "ALL": name = i18n.t('statusI18n.All'); break;
    case "1": name = i18n.t('statusI18n.AssetsFundable'); break;
    case "2": name = i18n.t('statusI18n.AssetsApplying'); break;
    case "3": name = i18n.t('statusI18n.AssetsApproved'); break;
    case "4": name = i18n.t('statusI18n.AssetsRejected'); break;
    case "9": name = i18n.t('statusI18n.AssetsExpired'); break;
    default: name = ""; break;
  }
  return name;
};
// 应收账款 类型
const filterBusinessType = (value) => {
  let name = "";
  switch (value) {
    case "1": name = i18n.t('statusI18n.AssetsAccountsReceivables'); break;
    // case "RECEIPT": name = i18n.t('statusI18n.AssetsWarehouseReceipt'); break;
    default: name = ""; break;
  }
  return name;
};
// 应收账款 支付类型
const filterRepayType = (value) => {
  let name = "";
  switch (value) {
    case 1: name = i18n.t('statusI18n.AssetsBank'); break;
    case 2: name = i18n.t('statusI18n.AssetsCommercialBill'); break;
    case 3: name = i18n.t('statusI18n.AssetsOthers'); break;
    default: name = ""; break;
  }
  return name;
};
// 时间戳转化为日期
const timesToDate = (time) => {
  time = Number(time);
  if (!time) return " ";
  let date = new Date(time);
  let Y = date.getFullYear() + '-';
  let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
  let D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate() + '';
  return Y + M + D;
};

// 融资管理——查看——历史融资信息
const repaymentMethod = (value) => {
  let name = "";
  switch (value) {
    case "1": name = i18n.t('equalPrincipal'); break;
    default: name = ""; break;
  }
  return name;
};

// 投资人——我的投资——投资状态
const myInvestStatus = (value) => {
  let name = "";
  switch (value) {
    case "1": name = i18n.t('investCreate.financAppointment'); break;
    case "2": name = i18n.t('investCreate.financInRevenue'); break;
    case "3": name = i18n.t('investCreate.financFinished'); break;
    case "9": name = i18n.t('investCreate.financFailed'); break;
    default: name = ""; break;
  }
  return name;
};

// Unit钱包——交易记录——类型
const unitType = (value) => {
  let name = "";
  switch (value) {
    case "ALL": name = i18n.t('unitPage.allType'); break;
    case "1": name = i18n.t('unitPage.depositType'); break;
    case "2": name = i18n.t('unitPage.withdrawalType'); break;
    case "3": name = i18n.t('unitPage.investType'); break;
    case "4": name = i18n.t('unitPage.incomeType'); break;
    case "5": name = i18n.t('unitPage.financingType'); break;
    case "6": name = i18n.t('unitPage.withdrawalRejectedType'); break;
    default: name = ""; break;
  }
  return name;
};

// Unit钱包——交易记录——状态
const unitStatus = (value) => {
  let name = "";
  switch (value) {
    case "ALL": name = i18n.t('unitPage.allType'); break;
    case "1": name = i18n.t('unitPage.verifyingStatus'); break;
    case "2": name = i18n.t('unitPage.reviewingStatus'); break;
    case "3": name = i18n.t('unitPage.approvedStatus'); break;
    case "4": name = i18n.t('unitPage.rejectedStatus'); break;
    default: name = ""; break;
  }
  return name;
};

export default {
  financingStatusId,
  loginType,
  numberFormatter,
  toThousandFilter,
  toThousandFilterTwo,
  uppercaseFirst,
  financingStatus,
  financingStat,
  timeStampToDate,
  repayIdStatus,
  orderStatus,
  productStatus,
  interestRate,
  authStatus,
  investStatus,
  numberFormat,
  reNumber,
  categoryFormat,
  processFormat,
  filterAssetsStatus,
  filterBusinessType,
  filterRepayType,
  timesToDate,
  financingStageStatus,
  repaymentMethod,
  myInvestStatus,
  unitType,
  unitStatus
};
