/*
 * @Author: 冯杰
 * @Date: 2020-03-03 18:04:51
 * @Last Modified by:   冯杰
 * @Last Modified time: 2020-03-03 18:04:51
 */

export default {
  key: `-----BEGIN RSA PRIVATE KEY-----
MIICXwIBAAKBgQDG/MI/jUAbRY5/Dh5R8M4rFKLly1oUaq2a+8lGhBwo4iPkpM5z
G34tPGibzDdsp1Ok1hiUFSuZiVYTj+FTZ/PY8bMhtTqO6GCeZgk6EH1yn6YjeCzE
e2LrpZNJXh39rvUmK8Mi/YrrhzjL680WpM48IARMWY9gGOIZZ0afwjGx9QIDAQAB
AoGBAML+oePhKTcQXGlJjpDBp2c0G8WQFr/3IhTENCM0BtxRbtb6YY0PHgB6OBCk
eJOzDRZ07zT94fHdUUpuLmKrdZofmBy45tajT1T4fqqxZCvV1nHry3DKMsnn0l4q
+Uh8BAFEXLlU1LRr9RAwHc0HVrBNNatdkHK5mj1iivM+/+oBAkEA+6KalUdN87SG
Y6kwL1eiVrYWGzpilIw+IO66klPHzn8I329L3BIpD1Mz2Ay4brZzp/8/8rSz+rZJ
+7FIZiyRwQJBAMpwXr+xoJFH9807odL+TDse2dAcIZsuxZWPHLr4baVqjrIBwBWf
6jEg3ixcs36yvCr7qabA1z11cFrZG2ZOxTUCQQCpWVODp4tUume/ZrvhAK9mgAD8
rhxuMLwXWdMCa5QK6v/Dr5l0MFylza8Udeshx3Sq2WPmKar+zfOYikVZTiGBAkEA
wdLtJOTRM8iQPfRGcFoCauiS62rD0mzpyNSJUYrikGqBzXVXkFzYQcmSby8ivMAs
4YqrWHlym+mBdbpBPadVsQJBAMReLgkH1dtg2T8qxfcT+Uk1jbhOfll2df1WQe6z
oZzkahwTO7O0asrnvds+ePH3aX6qYUHyWa872+L1XRcqL9o=
-----END RSA PRIVATE KEY-----` };
