
if(!window.ethCache){
  window.ethCache = {};
}

export async function cachedRequest(request) {
  if(ethCache[request]) {
    return ethCache[request];
  }
  
  ethCache[request] = await tradeFlowContract.methods[request[0]](...request.slice(1)).call();
  return ethCache[request];
}