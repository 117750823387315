export default {
  IMG_URL: "https://app-tradefinance-1259661131.cos.ap-hongkong.myqcloud.com", // 静态资源地址
  // UNIT_MILLION: 1000000, // 入库及合约需要 * ，展现需要 /
  UNIT_MILLION: 1, // 入库及合约需要 * ，展现需要 /
  UNIT_MILLIONS: 1000000, // 入库及合约需要 * ，展现需要 /
  AUTH: {
    UNKNOWN: {
      label: "未认证",
      value: 0
    },
    CONFIRM: {
      label: "已认证",
      value: 2
    },
    PROCESSING: {
      label: "认证中",
      value: 1
    },
    REJECT: {
      label: "驳回",
      value: 3
    }
  },
  AUTHSTATUS: {
    UNKNOWN: {
      label: "未认证",
      value: "UNKNOWN",
      valueNew: "0",
    },
    CONFIRM: {
      label: "已认证",
      value: "CONFIRM",
      valueNew: "2",
    },
    PROCESSING: {
      label: "认证中",
      value: "PROCESSING",
      valueNew: "1",
    },
    REJECT: {
      label: "驳回",
      value: "REJECT",
      valueNew: "3",
    },
    UNKNOWNTOO: {
      label: "未认证",
      value: "UNKNOWN",
      valueNew: "9",
    },
  },
  INVESTORTYPE: {
    ORGANIZATION: {
      label: "机构投资人",
      value: 1
    },
    PERSONAL: {
      label: "个人",
      value: 2
    }
  },
  EXPERIENCEINVESTMENT: {
    NONE: {
      label: "无",
      value: 1
    },
    ONE_TO_THREE: {
      label: "1-3年",
      value: 2
    },
    THREE_TO_FIVE: {
      label: "3-5年",
      value: 3
    },
    FIVE_TO_TEN: {
      label: "5-10年",
      value: 4
    },
    MORE_TEN: {
      label: "10年以上",
      value: 5
    }
  },
  RISKTOLERANCE: {
    KEEP: {
      label: "保守型",
      value: 1
    },
    PRUDENT: {
      label: "谨慎型",
      value: 2
    },
    STEADY: {
      label: "稳健型",
      value: 3
    },
    ACTIVE: {
      label: "积极型",
      value: 4
    },
    RADICAL: {
      label: "激进型",
      value: 5
    }
  },
  FINAC: [{ title: 'all', select: true, value: "All" }, { title: 'underApproval', select: false, value: "Approval" }, { title: 'inRevenue', select: false, value: "Fundraising" }, { title: 'financed', select: false, value: "Funded" }, { title: 'repaid', select: false, value: "Repaid" }],
  FINANCSTATUS: [{ title: 'all', select: true, value: "0" }, { title: 'financApproval', select: false, value: "1" }, { title: 'financFundsSent', select: false, value: "2" }, { title: 'financFunded', select: false, value: "3" }, { title: 'financRepaid', select: false, value: "4" }, { title: 'financReject', select: false, value: "5" }],
  CATEGORY: {
    RECHARGE: {
      label: "充值",
      value: "RECHARGE"
    },
    WITHDRAW: {
      label: "提现",
      value: "WITHDRAW"
    }
  },
  PROCESS: {
    FIRST: {
      label: "初审",
      value: "FIRST"
    },
    SECOND: {
      label: "复审",
      value: "SECOND"
    },
    CONFIRM: {
      label: "通过",
      value: "CONFIRM"
    },
    REJECT: {
      label: "驳回",
      value: "REJECT"
    },
  },
  ASSETS_STATUS: [{
    value: '',
    label: 'statusI18n.All'
  }, {
    value: '1',
    label: 'statusI18n.AssetsFundable'
  }, {
    value: '2',
    label: 'statusI18n.AssetsApplying'
  }, {
    value: '3',
    label: 'statusI18n.AssetsApproved'
  }, {
    value: '4',
    label: 'statusI18n.AssetsRejected'
  }, {
    value: '9',
    label: 'statusI18n.AssetsExpired'
  }],
  BUSINESS_TYPE: [{
    value: '1',
    label: 'statusI18n.AssetsAccountsReceivables'
  }],
  // , {
  //     value: 'RECEIPT',
  //     label: 'statusI18n.AssetsWarehouseReceipt'
  // }],
  REPAY_TYPE: [{
    value: 1,
    label: 'statusI18n.AssetsBank'
  }, {
    value: 2,
    label: 'statusI18n.AssetsCommercialBill'
  }, {
    value: 3,
    label: 'statusI18n.AssetsOthers'
  }],

  // unit——供应商角色——类型
  UNIT_SUPPLIER_TYPE: [
    {
      value: '100',
      label: 'unitPage.allType'
    },
    {
      value: '2',
      label: 'unitPage.withdrawalType'
    },
    {
      value: '5',
      label: 'unitPage.financingType'
    },
    {
      value: '6',
      label: 'unitPage.withdrawalRejectedType'
    }
  ],
  // unit——投资人角色——类型
  UNIT_INVEST_TYPE: [
    {
      value: '100',
      label: 'unitPage.allType'
    },
    {
      value: '1',
      label: 'unitPage.depositType'
    },
    {
      value: '2',
      label: 'unitPage.withdrawalType'
    },
    {
      value: '3',
      label: 'unitPage.investType'
    },
    {
      value: '4',
      label: 'unitPage.incomeType'
    },
    {
      value: '6',
      label: 'unitPage.withdrawalRejectedType'
    }
  ],
  // unit——供应商角色——充值提现记录——类型
  UNIT_RECORD_SUPPLIER_TYPE: [
    {
      value: '100',
      label: 'unitPage.allType'
    },
    {
      value: '2',
      label: 'unitPage.withdrawalType'
    }
  ],
  // unit——投资人角色——充值提现记录——类型
  UNIT_RECORD_INVEST_TYPE: [
    {
      value: '100',
      label: 'unitPage.allType'
    },
    {
      value: '1',
      label: 'unitPage.depositType'
    },
    {
      value: '2',
      label: 'unitPage.withdrawalType'
    }
  ],
  // unit——充值提现记录——状态
  UNIT_RECORD_STATUS: [
    {
      value: '100',
      label: 'unitPage.allType'
    },
    {
      value: '1',
      label: 'unitPage.verifyingStatus'
    },
    {
      value: '2',
      label: 'unitPage.reviewingStatus'
    },
    {
      value: '3',
      label: 'unitPage.approvedStatus'
    },
    {
      value: '4',
      label: 'unitPage.rejectedStatus'
    }
  ],
  BANK_COUNTRY_CITY: [{
    value: "1",
    label: "中国大陆",
    infos: [{
      bankName: "天地银行",
      bankNameValue: "天地银行",
      bankCode: "123123",
      bankCodeValue: "123123",
    }, {
      bankName: "大傻子银行",
      bankNameValue: "大傻子银行",
      bankCode: "456456",
      bankCodeValue: "456456",
    }]
  }, {
    value: "2",
    label: "香港",
    infos: [{
      bankName: "天地银行1",
      bankNameValue: "天地银行1",
      bankCode: "111111",
      bankCodeValue: "111111",
    }, {
      bankName: "天地银行2",
      bankNameValue: "天地银行2",
      bankCode: "222222",
      bankCodeValue: "222222",
    }]
  }, {
    value: "3",
    label: "其他地区",
    infos: []
  }],
  getObject(enums, value) {
    if (!Array.isArray(enums)) return "";
    return enums.find(item => item.value === value);
  },
};
