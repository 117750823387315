export const supplyList = [
  //  核心企业
  '/enterprise-all',
  '/enterprise-all/brand-owners-detail',
  '/financing-manage',
  '/financing-manage/supply-detail',
  '/mark-list',
  '/mark-list/mark-detail',
  '/order-manage',
  //  供应商
  '/supply-all',
  '/supply-all/supplier-detail',
  '/receivable-list',
  '/financing-list',
  '/financing-list/finance-info',
  '/supply-list',
  '/contract-list',
  '/repayment-list',
  '/repayment-list/repayment-detail',
  '/receivable-list/creat',
  '/supply-tr',
  '/supply-tr/recharge',
  '/supply-tr/withdrawal',
  '/supply-tr/record-logs',
  '/assets-list',
  '/assets-list/assets-finance',
  //  投资人
  '/invest-all',
  '/invest-all/investor-detail',
  '/invest-find',
  '/my-invest',
  '/capital-all',
  '/invest-all/detail',
  '/msg-center',
  '/customer-service',
  '/invest-all/recharge',
  '/invest-all/withdrawal',
  '/invest-tr',
  '/invest-tr/recharge',
  '/invest-tr/withdrawal',
  '/invest-tr/record-logs',
  // common & white
  '/bc-demo'
];
