/*
 * @Author: 卢林
 * @Date: 2019-11-29 17:07:47
 * @LastEditTime: 2020-03-10 16:02:25
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /vue-test/src/router.js
 */

import Vue from 'vue';
import Router from 'vue-router';
import store from '../plugins/store';
import * as _ from 'lodash';
import routerList from './router-list';
import investList from './invest-list';
import enterpriseList from './enterprise-list';
import commonList from './common-list';
import { supplyList } from './supply-side';
import { connect, resolveRoleId, getUser } from '../utils/blockchain';
import Storage from "@/utils/storage";

Vue.use(Router);
const router = new Router({
  mode: 'history', // 去掉路由地址的#
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'tips',
      redirect: '/login',
      component: () => import('../views/base.vue'),
      children: [
        ...commonList
      ]
    }, {
      path: '/',
      name: 'enterprise',
      redirect: '/enterprise-all',
      component: () => import('../views/enterprise.vue'),
      children: [
        ...enterpriseList
      ]
    }, {
      path: '/',
      name: 'base',
      redirect: '/supply-all',
      component: () => import('../views/base.vue'),
      children: [
        ...routerList
      ]
    }, {
      path: '/',
      name: 'invest',
      redirect: '/invest-all',
      component: () => import('../views/invest.vue'),
      children: [
        ...investList
      ]
    }, {
      path: '/login',
      name: 'login',
      // alias: 'login', // 别名
      component: () => import('../views/login.vue')
    },
    {
      path: '/bc-demo',
      name: 'bc-demo',
      // alias: 'login', // 别名
      component: () => import('../views/bc-demo.vue')
    },  {
      path: '/reviewPDF',
      name: 'reviewPDF',
      // alias: 'login', // 别名
      component: () => import('../views/reviewPDF.vue')
    },
    {
      path: '/register',
      name: 'register',
      // alias: 'register',
      component: () => import('../views/register.vue')
    },
    {
      // 处理路由错误
      path: '*',
      component: () => import('../views/page-notfound.vue')
    }
  ]
});

router.beforeEach(async (to, from, next) => {
  // 侧边栏展示
  await store.dispatch('auth/isSide', supplyList.includes(to.path));
  // 登陆
  if (to.path === '/login') {
    return next();
  }

  if (to.path === '/register') {
    return next();
  }
  if (to.path === '/register-admin') {
    return next();
  }

  if (to.path === '/bc-demo') {
    return next();
  }

  if (to.path === "/error-code") {
    return next();
  }

  // 白名单路由跳转之后 需要等待MetaMask返回结果之后再执行操作
  // getRole(to.path, from.path);
  // const isConfirm = JSON.parse(localStorage.getItem('isConfirm'));

  // async function getRole(toPath, fromPath) {
  //   await connect();
  //   let accounts = await web3.eth.getAccounts();
  //   let address = accounts[0];
  //   let user = await tradeFlowContract.methods.getUser(address).call();
  //   let loginName = user.name;
  //   let roleId = user.role;
  //   let authUser = store.getters["auth/user"].authorization;
  //   if (roleId === "0") {
  //     Storage.setLocalItem("isConfirm", false);
  //     return router.replace('/login').catch(error => { });
  //   }
  //   if (["4", "5"].includes(roleId)) {
  //     Storage.setLocalItem("isConfirm", false);
  //     return router.replace('/no-power').catch(error => { });
  //   }
  //   if (roleId !== sessionStorage.getItem("roleId") || address.toString() !== authUser.toString()) {
  //     sessionStorage.setItem("roleId", roleId);
  //     await loginUser(address, loginName, roleId, isConfirm);
  //     console.log("router 切换地址后的");
  //   } else {
  //     await loginUsers(address, loginName, roleId, isConfirm);
  //     console.log("router 没有切换地址的");
  //   }
  // }
  // console.log("router 外层");
  // 未登陆 跳转 登陆页面
  if (!store.getters['auth/user'] && to.path !== '/login') {
    return router.replace('/login');
  }

  const commonPath = commonList.find(item => {
    return item.path === to.path;
  });
  if (commonPath) {
    return next();
  }
  // if (!isConfirm && commonPath) {
  //   return next();
  // }

  const type = localStorage.getItem('type');
  // 投资人 验证路由
  if (type === 'INVEST') {
    const isPath = investList.find(item => {
      return item.path === to.path;
    });
    if (!isPath) {
      return router.replace('/invest-all').catch(error => { });
    }
  }
  // 供应商 验证路由
  if (type === 'LOAN') {
    const isPath = routerList.find(item => {
      return item.path === to.path;
    });
    if (!isPath) {
      return router.replace('/supply-all').catch(error => { });
    }
  }
  // 核心企业 验证路由
  if (type === 'CORE') {
    const isPath = enterpriseList.find(item => {
      return item.path === to.path;
    });
    if (!isPath) {
      return router.replace('/enterprise-all').catch(error => { });
    }
  }
  next();
});

// async function loginUsers(address, loginName, roleId, isConfirm) {
//   let registerUrls = roleId === '2' ? '/invest/user/register/address' : roleId === '1' ? '/loan/user/register/address' : '/core/user/register/address';
//   let data = {
//     address: address,
//     companyName: loginName,
//     userName: loginName
//   };
//   await axios({
//     url: registerUrls,
//     method: "POST",
//     data
//   }).then(result => {
//     const type = roleId === '2' ? 'INVEST' : roleId === '1' ? 'LOAN' : 'CORE';
//     store.dispatch('auth/loginSuccess', {
//       username: loginName,
//       id: result.id,
//       authorization: address,
//       type: type || 'INVEST'
//     });
//     const respStatus = {
//       UNKNOWN: "UNKNOWN",
//       PROCESSING: "PROCESSING",
//       CONFIRM: "CONFIRM",
//       REJECT: "REJECT"
//     };
//     Storage.setLocalItem("isConfirm", result.auth === respStatus.CONFIRM);
//     let existCodeUrl = result.existCode ? "/company-create" : "/invitation-code";
//     let pageFlag = result.auth === respStatus.UNKNOWN ? 0 : result.auth === respStatus.PROCESSING ? 1 : result.auth === respStatus.CONFIRM ? 9 : 99;
//     let routerLink = "/";
//     switch (roleId) {
//       case '2':
//         routerLink = pageFlag === 0 ? "/invest-create" : pageFlag === 1 ? "/review" : pageFlag === 9 ? "/invest-all" : "/reject";
//         break;
//       case '1':
//         routerLink = pageFlag === 0 ? existCodeUrl : pageFlag === 1 ? "/review" : pageFlag === 9 ? "/supply-all" : "/reject";
//         break;
//       case '3':
//         routerLink = pageFlag === 0 ? "/enterprise-create" : pageFlag === 1 ? "/review" : pageFlag === 9 ? "/enterprise-all" : "/reject";
//         break;
//       default:
//         router.replace('/login');
//         break;
//     }
//     if (!isConfirm && (result.auth === respStatus.CONFIRM)) router.replace({ path: routerLink }).catch(error => { });
//     if (!['/invest-all', '/supply-all', '/enterprise-all'].includes(routerLink)) router.replace({ path: routerLink }).catch(error => { });
//   }).catch(err => { console.log("login error {}", err); });
// }

// async function loginUser(address, loginName, roleId) {
//   let registerUrl = roleId === '2' ? '/invest/user/register/address' : roleId === '1' ? '/loan/user/register/address' : '/core/user/register/address';
//   let data = {
//     address: address,
//     companyName: loginName,
//     userName: loginName
//   };
//   await axios({
//     url: registerUrl,
//     method: "POST",
//     data
//   }).then(result => {
//     const type = roleId === '2' ? 'INVEST' : roleId === '1' ? 'LOAN' : 'CORE';
//     store.dispatch('auth/loginSuccess', {
//       username: loginName,
//       id: result.id,
//       authorization: address,
//       type: type || 'INVEST'
//     });
//     localStorage.setItem('type', type); // 登陆角色记录
//     // 根据返回的状态以及当前选中的角色，判断跳转路由 UNKNOWN:未认证;PROCESSING:认证中;CONFIRM:已认证;REJECT:认证拒绝;
//     const respStatus = {
//       UNKNOWN: "UNKNOWN",
//       PROCESSING: "PROCESSING",
//       CONFIRM: "CONFIRM",
//       REJECT: "REJECT"
//     };
//     Storage.setLocalItem("isConfirm", result.auth === respStatus.CONFIRM);
//     let existCodeUrl = result.existCode ? "/company-create" : "/invitation-code";
//     let pageFlag = result.auth === respStatus.UNKNOWN ? 0 : result.auth === respStatus.PROCESSING ? 1 : result.auth === respStatus.CONFIRM ? 9 : 99;
//     let routerLink = "/";
//     switch (roleId) {
//       case '2':
//         routerLink = pageFlag === 0 ? "/invest-create" : pageFlag === 1 ? "/review" : pageFlag === 9 ? "/invest-all" : "/reject";
//         break;
//       case '1':
//         routerLink = pageFlag === 0 ? existCodeUrl : pageFlag === 1 ? "/review" : pageFlag === 9 ? "/supply-all" : "/reject";
//         break;
//       case '3':
//         routerLink = pageFlag === 0 ? "/enterprise-create" : pageFlag === 1 ? "/review" : pageFlag === 9 ? "/enterprise-all" : "/reject";
//         break;
//       default:
//         router.replace('/login');
//         break;
//     }

//     const commonPath = commonList.find(item => {
//       return item.path === routerLink;
//     });
//     store.dispatch('auth/isSide', !commonPath);
//     router.replace({ path: routerLink }).catch(error => { });
//   }).catch(err => { console.log("login error {}", err); });
// }


export default router;
