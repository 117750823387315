
import i18n from './i18n';
// 账号效验
const validateAccount = (rule, value, callback) => {
  if (value === '') {
    callback(new Error(i18n.t('loginPage.accountErr')));
  } else {
    callback();
  }
};

// 密码效验
const validatePass = (rule, value, callback) => {
  if (value === '') {
    callback(new Error(i18n.t('loginPage.passwordErr')));
  } else if (!/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,16}$/gi.test(value)) {
    callback(new Error(i18n.t('registerPage.passwordFormat')));
  } else {
    callback();
  }
};

// 图形验证码校验
const validateGraphical = (rule, value, callback) => {
  if (value === '') {
    callback(new Error(i18n.t('loginPage.graphicalCodeErr')));
  } else {
    callback();
  }
};

// 注册供应商、核心企业账号效验
const validateRegAccount = (rule, value, callback) => {
  if (value === '') {
    callback(new Error(i18n.t('registerPage.fullNameErr')));
  } else {
    callback();
  }
};

// 注册投资人账号效验
const validateInvestorAccount = (rule, value, callback) => {
  if (value === '') {
    callback(new Error(i18n.t('registerPage.investorErr')));
  } else {
    callback();
  }
};

// 邮箱效验
const validateEmail = (rule, value, callback) => {
  if (value === '') {
    callback(new Error(i18n.t('registerPage.emailErr')));
  } else if (!(/^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/gi.test(value))) {
    callback(new Error(i18n.t('registerPage.emailFormat')));
  } else {
    callback();
  }
};

// 邮箱验证码效验
const validateEmailCode = (rule, value, callback) => {
  if (value === '') {
    callback(new Error(i18n.t('registerPage.emailCodeErr')));
  } else {
    callback();
  }
};
export default {
  validateAccount,
  validatePass,
  validateGraphical,
  validateRegAccount,
  validateEmail,
  validateEmailCode,
  validateInvestorAccount
};
