
import tradeFlowJson from '../data/TradeFlow.json';
import Web3 from 'web3';
import { lowerCase } from 'lodash';
import BigNumber from "bignumber.js";
import {cachedRequest} from './eth-cache' ;

function calculatAannualizedIncome(ask, repay, days) {
  return (((((repay / ask) - 1) / days) * 365) * 100).toFixed(2);
}

export async function getFinanceRequests(tradeFlowContract) {
  let frs = await tradeFlowContract.methods.getAllFinanceRequests().call();
  frs = frs.map(async f => {
    let row = {};

    row.id = f.id;
    row.supplierAddress = f.supplier;
    row.supplier = (await cachedRequest(['getUser', row.supplierAddress])).name;
    row.askAmount = web3.utils.fromWei(f.askAmount);
    row.repayAmount = web3.utils.fromWei(f.repayAmount);
    row.description = f.description;
    row.length = f._length;
    row.buyer = f.buyer;
    row.fundsSent = f.fundsSent;
    row.repaid = f.repaid;
    row.manufacturingStartDate = f.manufacturingStartDate;
    row.purpose = f.financingPurpose;
    row.blockNumber = f.blockNumber;
    row.apr = calculatAannualizedIncome(parseFloat(row.askAmount), parseFloat(row.repayAmount), parseFloat(row.length));
    row.statusId = f.status;
    row.status = resolveFinanceRequestStatus(f.status);
    row.brandOwnerAddress = f.brandOwner;
    row.brandOwner = (await cachedRequest(['getUser', row.brandOwnerAddress])).name;
    row.extraData = f.extraData;
    row.fundsProvided = await Promise.all(f.financeProvided.map(async fp => {
      let mapped = {};
      mapped.amount = web3.utils.fromWei(fp.amount);
      mapped.financierAddress = fp.financier;
      mapped.financier = (await cachedRequest(['getUser', mapped.financierAddress])).name;
      return mapped;
    }));
    row.fundingSum = 0;
    if (row.fundsProvided.length > 0) {
      row.fundingSum = row.fundsProvided.map(f => { return parseFloat(f.amount); }).reduce((x, y) => { return x + y; });
    }
    return row;
  });

  return Promise.all(frs);
}

export async function getSupplierOverview() {
  let frs = await tradeFlowContract.methods.getAllFinanceRequests().call();

  let outstandingAmount = 0.0;
  let numberOutstandingTransactions = 0;
  let totalHistoricalFinancingAmountCompleted = 0.0;
  let historicalNumberFinancingTransactionCompleted = 0;

  for (let fr of frs) {
    let amount = web3.utils.fromWei(fr.askAmount);
    let amountFloat = parseFloat(amount);
    if(fr.repaid) {
      totalHistoricalFinancingAmountCompleted += amountFloat;
      historicalNumberFinancingTransactionCompleted++;
    }
    else {
      outstandingAmount += amountFloat;
      numberOutstandingTransactions++;
    }
  }
  return { 
    outstandingAmount, 
    numberOutstandingTransactions, 
    totalHistoricalFinancingAmountCompleted, 
    historicalNumberFinancingTransactionCompleted 
  };
}

export async function connect() {
  if (window.tradeFlowContract) {
    return; //We are already connected
  }
  let web3Provider;
  if (window.ethereum) {
    web3Provider = window.ethereum;
    try {
      // Request account access
      await window.ethereum.enable();
    } catch (error) {
      // User denied account access...
      console.error("User denied account access");
    }
  }
  // Legacy dapp browsers...
  else if (window.web3) {
    web3Provider = window.web3.currentProvider;
  }
  // If no injected web3 instance is detected, fall back to Ganache
  else {
    web3Provider = new Web3.providers.HttpProvider('http://localhost:8545');
  }
  window.web3 = new Web3(web3Provider);

  window.contractAddress = tradeFlowJson['networks']['10']['address'];

  window.tradeFlowContract = new web3.eth.Contract(tradeFlowJson['abi'], contractAddress);
  tradeFlowContract.setProvider(web3);
}

export function resolveRoleId(roleId) {
  switch (roleId) {
    case "0":
      return 'Unregistered';
    case "1":
      return 'Supplier';
    case "2":
      return 'Financier';
    case "3":
      return 'BrandOwner';
    case "4":
      return 'Administrator';
    case "5":
        return 'Finance Manager';
    case "6":
        return 'Senior Finance Manager';
    }
}

export function resolveFinanceRequestStatus(statusId) {
  switch(statusId){
    case "0":
      return 'Unapproved';
    case "1":
      return 'Approved';
    case "2":
      return 'Rejected';
  }
}

export async function getMyBalance() {
  let address = (await web3.eth.getAccounts())[0];
  let balance = await tradeFlowContract.methods.balanceOf(address).call();
  return BigNumber(web3.utils.fromWei(balance));
}

function mapUser(u) {
  let mapped = {};
  mapped.approved = u.approved;
  mapped.name = u.name;
  mapped.roleId = u.role;
  mapped.role = resolveRoleId(u.role);
  mapped.address = u._address;
  return mapped;
}

export async function getUsers() {
  let users = await tradeFlowContract.methods.getAllUsers().call();
  users = users.map(mapUser);
  return users;
}

export async function getUser(address) {
  let user = await tradeFlowContract.methods.getUser(address).call();
  return mapUser(user);
}

export async function getExchangeRequests() {
  let requests =  await tradeFlowContract.methods.getExchangeRequests().call();
  return await Promise.all(requests.map(async (r) => {
    let rq = {};
    rq.amount = web3.utils.fromWei(r.amount);
    rq.directionId = r.direction;
    rq.direction = rq.directionId == "0" ? "Deposit" : "Withdrawal";
    rq.seniorFinanceManagerApproved = r.seniorFinanceManagerApproved;
    rq.financeManagerApproved = r.financeManagerApproved;
    rq.paid = r.paid;
    rq.id = r.id;
    rq.rejectionReason = r.rejectionReason;
    rq.rejected = r.rejected;
    rq.requesterId = r.requester;
    rq.requester = (await getUser(r.requester)).name;
    return rq;
  }));
}