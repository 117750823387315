// 供应端 下的子类路由
'use strict';
export default [
  //  tips
  {
    path: '/root',
    component: () => import('../views/root.vue'),
  },
  {
    path: '/error-code',
    component: () => import('../views/show-tips/tips-error-code.vue'),
  },
  {
    path: '/no-power',
    component: () => import('../views/show-tips/tips-no-power.vue'),
  },
  {
    path: '/reject',
    component: () => import('../views/show-tips/tips-reject.vue'),
  },
  {
    path: '/review',
    component: () => import('../views/show-tips/tips-review.vue'),
  },
  {
    path: '/wait',
    component: () => import('../views/show-tips/tips-wait.vue'),
  },
  {
    path: '/invitation-code',
    component: () => import('../views/company/invitation-code.vue'),
  }, {
    path: '/enterprise-create',
    component: () => import('../views/enterprise/enterprise-create.vue'),
  },
  {
    path: '/company-create',
    component: () => import('../views/company/company-create.vue'),
  },
  {
    path: '/invest-create',
    component: () => import('../views/investor/invest-create.vue'),
  }
];
