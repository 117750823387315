/*
 * @Author: your name
 * @Date: 2020-03-04 22:44:26
 * @LastEditTime: 2020-03-05 09:55:10
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /advokate-vue-base/src/plugins/axios.js
 */
'use strict';

import Vue from 'vue';
import axios from 'axios';
import store from './store.js';
import { sign } from '../utils/validate';
import router from '../routers/index';
import { Message } from 'element-ui';
import { resolve } from 'any-promise';
// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

let config = {
  baseURL: '/api'
  // baseURL: process.env.baseURL || process.env.apiUrl || ""
  // timeout: 60 * 1000, // Timeout
  // withCredentials: true, // Check cross-site Access-Control
};

// #region 设置加密和加签
const configSetting = config => {
  if (config.method === 'post') {
    sign(config);
  }
  if (store.getters['auth/token']) {
    config.headers.token = store.getters['auth/token'];
    // config.headers.address = store.getters['auth/user'].authorization;
    // config.headers.type = store.getters['auth/user'].type;
  }
  config.headers.lang = store.getters['auth/language'] === 'zh-cn' ? 'zh-cn' : 'en';
  return config;
};
// #endregion

const _axios = axios.create(config);

_axios.interceptors.request.use(
  configSetting,
  (error) => {
    // Do something with request error
    return Promise.reject(error);
  }
);
// var Promise = require('any-promise');
// Add a response interceptor
_axios.interceptors.response.use(
  (response) => {
    // Do something with response data
    const resp = response.data || null;
    // if (resp.code === 1 || resp.code === '1') {
    //   Message.error(resp.message);
    //   return false;
    // }
    return resp;
    // return new Promise((resolve, reject) => {
    //   if (response.data.code === '200' || response.data.code === '0' || response.data.code === 200 || response.data.code === 0) {
    //     return resolve(response.data.data);
    //   } else {
    //     if (response.data.code !== '9999') {
    //       Message.error(response.data.message);
    //     }
    //     return reject(response.data.code);
    //     // return reject(new Error('请求超时, 请刷新重试'));
    //   }
    //   // if (response.data.code === '200' || response.data.code === '0') {
    //   //   return resolve(response.data.data);
    //   // } else {
    //   //   if (response.data.code !== '9999') {
    //   //     Message.error(response.data.message);
    //   //   }
    //   //   return reject(response.data.code);
    //   //   // return reject(new Error('请求超时, 请刷新重试'));
    //   // }
    // });
  },
  (error) => {
    // Do something with response error
    if (error.response.status === 401 || (error.response.status === 500 &&
      error.response.statuscode === 401.1)) {
      // 重新登录
      return router.replace({
        path: '/login',
        query: { redirect: router.currentRoute.fullPath }
      });
    }
    if (error.response.status === 500 &&
      error.response.statuscode === 401.2) {
      return;
    }
    if (process.env.NODE_ENV !== 'production' && error.response.status === 422) {
      return;
    }
    return Promise.reject(error.response.data);
  }
);

Plugin.install = (Vue, options) => {
  Vue.axios = _axios;
  window.axios = _axios;
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return _axios;
      }
    },
    $axios: {
      get() {
        return _axios;
      }
    }
  });
};

Vue.use(Plugin);

export default Plugin;
