// 投资人路由 类
'use strict';
export default [
  {
    path: '/invest-all',
    component: () => import('../views/investor/invest-all.vue'),
  },
  {
    path: '/invest-all/investor-detail',
    component: () => import('../views/investor/investor-detail.vue'),
  },
  {
    path: '/my-invest',
    component: () => import('../views/investor/my-invest.vue'),
  },
  {
    path: '/invest-all/detail',
    component: () => import('../views/investor/capital-detail.vue'),
  },
  {
    path: '/capital-all',
    component: () => import('../views/investor/capital-all.vue'),
  },
  {
    path: '/invest-find',
    component: () => import('../views/investor/invest-find.vue'),
  },
  {
    path: '/invest-find/pro-detail',
    component: () => import('../views/investor/pro-detail.vue'),
  },
  {
    path: '/pro-confirm',
    component: () => import('../views/investor/pro-confirm.vue'),
  },
  {
    path: '/msg-center',
    component: () => import('../views/investor/msg-center.vue'),
  },
  {
    path: '/customer-service',
    component: () => import('../views/investor/customer-service.vue'),
  },
  {
    path: '/invest-tr',
    component: () => import('../views/account/transaction-records.vue'),
  },
  {
    path: '/invest-tr/recharge',
    component: () => import('../views/account/recharge.vue'),
  },
  {
    path: '/invest-tr/withdrawal',
    component: () => import('../views/account/withdrawal.vue'),
  },
  {
    path: '/invest-tr/record-logs',
    component: () => import('../views/account/record-logs.vue'),
  }
];
