/*
 * @Author: 吴占超
 * @Date: 2019-04-26 14:22:12
 * @Last Modified by: 侯琳
 * @Last Modified time: 2022-10-28 11:45:29
 */
import Storage from '../utils/storage.js';
import { validKey } from '../utils/validate';
// import _ from 'lodash';

// 全局vuex
export default {
  namespaced: true,
  // module assets
  state: {
    // 当前登陆人
    user: undefined,
    // 未解密的token
    token: undefined,
    isSide: true,
    language: "",
    isConfirm: true
  },
  getters: {
    /**
     * 获取当前登陆用户
     *
     * @returns
     */
    user(state, getters) {
      let user = Storage.getSessionItem('user');
      if (!state.user && user) {
        state.user = user;
      }
      return state.user;
    },
    /**
     * 未解密token
     *
     * @param {*} state
     * @returns
     */
    token(state) {
      let def = Storage.getSessionItem('token');
      if (!state.token && def) {
        state.token = def;
      }
      return state.token;
    },
    isSide(state) {
      let def = Storage.getLocalItem('isSide');
      if (!state.isSide && def) {
        state.isSide = def;
      }
      return state.isSide;
    },
    language(state) {
      let def = Storage.getLocalItem('i18n');
      if (!state.language && def) {
        state.language = def;
      }
      return state.language;
    },
    isConfirm(state) {
      let def = Storage.getLocalItem('isConfirm');
      if (!state.isConfirm && def) {
        state.isConfirm = def;
      }
      return state.isConfirm;
    }
  },
  mutations: {
    /**
     * 登陆成功
     *
     * @param {any} state
     * @param {any} [user=validKey()]
     */
    loginSuccess(state, user = validKey()) {
      state.user = user;
      state.token = user.token;
    },
    /**
     * 退出
     *
     * @param {any} state
     */
    logoutSuccess(state) {
      state.user = undefined;
      state.token = undefined;
    },
    /**
     * 未解密token
     *
     * @param {*} state
     * @param {*} value
     */
    token(state, value) {
      state.token = value;
    },
    isSide(state, value) {
      state.isSide = value;
    },
    language(state, value) {
      state.language = value;
    },
    isConfirm(state, value) {
      state.isConfirm = value;
    }
  },
  actions: {
    /**
     * 登陆成功方法
     *
     * @param {any} context
     * @param {any} user
     */
    loginSuccess(context, user = validKey()) {
      Storage.setSessionItem('user', user);
      Storage.setSessionItem('token', user.token);
      context.commit('loginSuccess', user);
    },
    /**
     * 清空登陆人
     *
     * @param {any} context
     */
    logoutSuccess(context) {
      Storage.clearSession('user');
      Storage.clearSession('token');
      context.commit('logoutSuccess');
    },
    isSide(context, isSide = validKey()) {
      Storage.setLocalItem('isSide', isSide);
      context.commit('isSide', isSide);
    },
    language(context, language = validKey()) {
      Storage.setLocalItem('language', language);
      context.commit('language', language);
    },
    isConfirm(context, isConfirm = validKey()) {
      Storage.setLocalItem('isConfirm', isConfirm);
      context.commit('isConfirm', isConfirm);
    }
  }
};
