// 供应端 下的子类路由
'use strict';
export default [
  {
    path: '/supply-all',
    component: () => import('../views/company/supply-all.vue')
  },
  {
    path: '/supply-all/supplier-detail',
    component: () => import('../views/company/supplier-detail.vue')
  },
  {
    path: '/receivable-list',
    component: () => import('../views/company/receivable-list.vue'),
  },
  {
    path: '/financing-list/financing-create',
    component: () => import('../views/company/financing-create.vue'),
  },
  {
    path: '/receivable-list/creat',
    component: () => import('../views/company/receivable-creat.vue'),
  },
  {
    path: '/financing-list',
    component: () => import('../views/company/financing-list.vue'),
  },
  {
    path: '/financing-list/finance-info',
    component: () => import('../views/company/finance-info.vue'),
  },
  {
    path: '/repayment-list',
    component: () => import('../views/company/repayment-list.vue'),
  },
  {
    path: '/repayment-list/repayment-detail',
    component: () => import('../views/company/repayment-detail.vue'),
  },
  {
    path: '/supply-list',
    component: () => import('../views/company/supply-list.vue'),
  },
  {
    path: '/contract-list',
    component: () => import('../views/company/contract-list.vue'),
  },
  {
    path: '/supply-tr',
    component: () => import('../views/account/transaction-records.vue'),
  },
  {
    path: '/supply-tr/recharge',
    component: () => import('../views/account/recharge.vue'),
  },
  {
    path: '/supply-tr/withdrawal',
    component: () => import('../views/account/withdrawal.vue'),
  },
  {
    path: '/supply-tr/record-logs',
    component: () => import('../views/account/record-logs.vue'),
  },
  {
    path: '/assets-list',
    component: () => import('../views/company/assets-list.vue'),
  },
  {
    path: '/assets-list/assets-finance',
    component: () => import('../views/company/assets-finance.vue'),
  },
  {
    path: '/assets-list/assets-create',
    component: () => import('../views/company/assets-create.vue'),
  },
  {
    path: '/assets-list/assets-update',
    component: () => import('../views/company/assets-update.vue'),
  },
  {
    path: '/assets-list/assets-info',
    component: () => import('../views/company/assets-info.vue'),
  }
];
