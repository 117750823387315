/*
 * @Author: your name
 * @Date: 2020-03-04 22:44:26
 * @LastEditTime: 2020-03-05 22:08:52
 * @LastEditors: your name
 * @Description: In User Settings Edit
 * @FilePath: /advokate-vue-base/src/plugins/store.js
 */
import Vue from 'vue';
import Vuex from 'vuex';
import auth from '../vuex/auth';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth
  }
});
