/*
 * @Author: 卢林
 * @Date: 2019-11-27 14:53:25
 * @LastEditTime: 2020-03-05 21:49:05
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /vue-test/src/main.js
 */

import Vue from 'vue';
import App from './App.vue';
import router from './routers';
import axios from './plugins/axios';
import store from './plugins/store';
import i18n from './plugins/i18n';
import './plugins/rules';
import '@fortawesome/fontawesome-free/css/all.min.css';

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './common/element-variables.scss';
import { format } from 'date-fns';
import filters from "./filters";
// import * as filters from "./filters/index";
import enums from "./utils/enums";

// register global utility filters
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key]);
});
// 全局组件
Vue.component('global-com');
Vue.use(ElementUI);
Vue.config.productionTip = false;
// Vue.prototype.$axios = axios;
Vue.prototype.$enums = enums;
Vue.prototype.formatTime = (date) => {
  return format(date, 'yyyy-MM-dd');
};
Vue.prototype.formatTimeHm = (date) => {
  return format(date, 'yyyy-MM-dd HH-mm');
};
localStorage.setItem("version", process.env.VUE_APP_VERSION);
new Vue({
  router,
  store,
  axios,
  i18n,
  render: h => h(App)
}).$mount('#app');
