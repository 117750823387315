<template>
  <!-- <transition name="el-zoom-in-center"> -->
  <router-view />
  <!-- </transition> -->
</template>

<script>
export default {
  name: 'app',
  data() {
    return {};
  }
};
</script>

<style lang="scss" >
/*每个页面公共css */
@import "./common/global.scss";
.cell {
  word-break: break-word !important;
}
</style>
