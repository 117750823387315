// 投资人路由 类
'use strict';
export default [
  {
    path: '/enterprise-all',
    component: () => import('../views/enterprise/enterprise-all.vue'),
  },  {
    path: '/enterprise-all/brand-owners-detail',
    component: () => import('../views/enterprise/brand-owners-detail.vue'),
  }, {
    path: '/financing-manage',
    component: () => import('../views/enterprise/financing-manage.vue'),
  }, {
    path: '/financing-manage/supply-detail',
    component: () => import('../views/enterprise/supply-detail.vue'),
  }, {
    path: '/mark-list',
    component: () => import('../views/enterprise/mark-list.vue'),
  }, {
    path: '/mark-list/mark-detail',
    component: () => import('../views/enterprise/mark-detail.vue'),
  }, {
    path: '/order-manage',
    component: () => import('../views/enterprise/order-manage.vue'),
  }
];
